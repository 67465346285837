<template>
  <div class="v-step v-step--1">

    <br><br>
    <h2>{{ __('step_1.get_to_know') }}</h2>
    <br><br>

    <div class="profils">
      <div class="profil part"></div>
    </div>

    <div class="container">

      <div class="row status_list">
        <div class="card card__part" :class="{active: form.main_user.status==='part'  }" @click="changeStatus('part')">
          <div class="card__container">
            <div class="card__text-container">
              <span><b>Particulier</b></span>
            </div>
          </div>
        </div>

        <div class="card card__idf" :class="{active: form.main_user.status==='idf'  }" @click="changeStatus('idf')">
          <div class="card__container">
            <div class="card__text-container">
              <span><b>Résident<br>Île-de-France</b></span>
            </div>
          </div>
        </div>

        <div class="card card__agent_territorial" :class="{active: form.main_user.status==='agent_territorial'  }" @click="changeStatus('agent_territorial')">
          <div class="card__container">
            <div class="card__text-container">
              <span><b> Agent territorial</b></span>
            </div>
          </div>
        </div>

        <div class="card card__agent_etat" :class="{active: form.main_user.status==='agent_etat'  }" @click="changeStatus('agent_etat')">
          <div class="card__container">
            <div class="card__text-container">
              <span><b>Agent d'état</b></span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <form novalidate :class="{'has-spouse':has_spouse}" class="form" v-if="google">
      <div class="form-container" :class="'additional-users-'+ form.additional_users.length">

        <div v-if="this.form.main_user.status">
          <h3 id="form_begin">{{ __('step_1.fill') }}</h3>
          <small class="mentions">{{ __('step_1.mentions') }}</small>

          <vue-form-generator tag="div" class="main_user" :schema="schema.main_user" :model="form.main_user"
                              :options="formOptions" ref="user"></vue-form-generator>

          <div class="additional-users" v-for="(additional_user, key) in form.additional_users">
            <vue-form-generator tag="div" class="additional-user" :schema="getAdditionalUserSchema(key)"
                                :model="additional_user" :options="formOptions" ref="additional"></vue-form-generator>
            <div class="delete-container">
              <a class="link" @click.prevent="remove(key)">{{ __('step_1.delete') }}</a>
            </div>
          </div>

          <div class="add-container">
            <button class="button button--light button--add" @click.prevent="add()"
                    v-if="form.additional_users.length < 8" data-icon="people">{{ __('step_1.add_a_beneficiary') }}
            </button>
          </div>

          <div class="content-block">
            <div class="picker-block">
              <h3>{{ __('step_2.start_date') }}</h3>
              <month-picker v-model="form.start_date"></month-picker>
            </div>
            <div class="subscription-before" v-html="__('step_2.subscription_before')"></div>
          </div>

          <vue-form-generator tag="div" class="general" :schema="schema.general" :model="form.general"
                              :options="formOptions"></vue-form-generator>

          <small class="specific-regime">{{ __('step_1.specific_regime') }}</small>
        </div>
      </div>

      <div class="submit-container" v-if="this.form.main_user.status">
        <div class="input-container" :class="{'loading':loading}">
          <button @click.prevent="validateTest()" class="button button--submit">{{ __('step_1.cta') }}</button>
        </div>
      </div>

      <div class="specific-container">
        <small class="rgpd" v-html="__('step_1.rgpd')"></small>
      </div>
    </form>

    <transition name="fade">
      <div class="c-popin" v-if="showErrorCode">
        <div class="c-popin-content code_content">
          <h6 class="c-popin-title errorcode"> {{ titlePopin }}</h6>
          <p class="email code_message">{{ messagecontent }}</p>
          <div class="button-container">
            <button class="button" @click="closepopin()">{{ btnpopinname }}</button>
          </div>
        </div>
      </div>
    </transition>

  </div>
</template>

<script>
import VueFormGenerator from 'vue-form-generator'
import MonthPicker from '@/components/MonthPicker.vue'
import GoogleMapsApiLoader from "google-maps-api-loader";

export default {
  name: 'step1',
  components: {
    MonthPicker
  },

  data() {
    return {
      forbiddenNumbers: ['0600000000', '0123456789', '0606060606', '0666666666', '0601020304', '0000000000', '0102030405', '0612345678', '0100000000', '0600000001', '0601010101', '0101010101', '0202020202', '0303030303', '0404040404', '0505050505', '0707070707', '0808080808', '0909090909', '0000000001', '0000000002', '0000000003', '0000000004', '0000000005', '0000000006', '0000000007', '0000000008', '0000000009'],
      index: 0,
      preventValidityCheck: false,
      loading: false,
      showedPopinIdf: false,
      has_spouse: false,
      google: false,
      showErrorCode: false,
      titlePopin: '',
      messagecontent: '',
      btnpopinname: '',
      listcode: [],
      form: {
        start_date: false,
        main_user: {
          civility: '',
          firstname: '',
          lastname: '',
          birthday: '',
          postal_code: '',
          email: '',
          phone: '',
          cmi_ref: false,
          status: false
        },
        general: {},
        additional_users: []
      },
      schema: {
        main_user: {},
        additional_user: {},
        general: {}
      },
      formOptions: {
        validateAfterChanged: true,
        // validateAfterLoad: true
      }
    }
  },

  watch: {
    form: {
      handler() {
        let has_spouse = false;
        this.form.additional_users.forEach(function (additional_user) {
          if (additional_user['type_' + additional_user.index] === 'spouse')
            has_spouse = true;
        });
        this.has_spouse = has_spouse;
      },
      deep: true
    },
  },

  methods: {
    validateTest() {
      let vfgs = this.$children.filter(component => component.constructor.options.name === 'formGenerator');

      let validate = vfgs.every(form => form.validate())
      if (validate)
        this.submit(false)
      else {
        this.scrollToElement('#form_begin');
      }
    },
    changeStatus(value) {
      this.form.main_user.status = value
    },
    add() {
      if (this.form.additional_users.length <= 8) {
        let additional_users = {
          index: this.index,
          firstname: '',
          lastname: '',
          birthday: ''
        };
        additional_users['type_' + this.index] = this.has_spouse ? 'child' : (this.form.additional_users.length === 7 && !this.has_spouse ? 'spouse' : '');
        additional_users['civility_' + this.index] = '';

        this.form.additional_users.push(additional_users);

        this.index++;
      }
    },
    remove(position) {

      let additional_users_new_list = [];
      let pos_user = 0;
      this.form.additional_users.forEach(function (additional_user) {
        if(additional_user.index!=position) {
          let additional_users_new = {
            index: pos_user,
            firstname: additional_user['firstname'],
            lastname: additional_user['lastname'],
            birthday: additional_user['birthday']
          };

          additional_users_new['civility_' + pos_user] = additional_user['civility_' + additional_user.index];
          additional_users_new['type_' + pos_user] = additional_user['type_' + additional_user.index];

          additional_users_new_list.push(additional_users_new);

          pos_user = pos_user + 1;
        }
      });

      this.form.additional_users = additional_users_new_list;
      this.index = additional_users_new_list.length;
    },
    getAdditionalUserSchema(key) {
      return {
        groups: [
          {
            fields: [
              {
                type: 'radios',
                label: this.__('step_1.i_also_want_to_protect'),
                model: 'type_' + key,
                inputName: 'type',
                required: true,
                values: [
                  {name: this.__('step_1.my_spouse'), value: 'spouse'},
                  {name: this.__('step_1.my_child'), value: 'child'}
                ],
                styleClasses: ['type-radio', 'type-radio--spouse'],
                validator: VueFormGenerator.validators.regexp.locale({
                  fieldIsRequired: this.__('errors.radios')
                })
              },
              {
                type: 'radios',
                model: 'civility_' + key,
                inputName: 'civility',
                required: true,
                values: [
                  {name: this.__('step_1.woman'), value: 'woman'},
                  {name: this.__('step_1.men'), value: 'man'}
                ],
                styleClasses: ['form-group--inline', 'form-group--large'],
                validator: VueFormGenerator.validators.regexp.locale({
                  fieldIsRequired: this.__('errors.beneficiary_gender')
                })
              },
              {
                type: 'input',
                inputType: 'date',
                inputName: 'birthday',
                min: '1900-01-01',
                max: '2100-01-01',
                required: true,
                label: this.__('step_1.other_birthday'),
                placeholder: '_ _ / _ _ / _ _ _ _',
                model: 'birthday',
                styleClasses: ['form-group--inline', 'form-group--small', 'input-date'],
                validator: VueFormGenerator.validators.regexp.locale({
                  fieldIsRequired: this.__('errors.beneficiary_birthday')
                })
              },
              {
                type: 'input',
                inputType: 'text',
                required: true,
                label: this.__('step_1.other_lastname'),
                model: 'lastname',
                styleClasses: ['form-group--inline', 'form-group--large'],
                pattern: '^[a-zA-ZÀ-ÖØ-öø-ÿ \']*$',
                validator: VueFormGenerator.validators.regexp.locale({
                  fieldIsRequired: this.__('errors.beneficiary_name'),
                  invalidFormat: this.__('errors.alpha')
                })
              },
              {
                type: 'input',
                inputType: 'text',
                required: true,
                label: this.__('step_1.other_firstname'),
                model: 'firstname',
                styleClasses: ['form-group--inline', 'form-group--large'],
                pattern: '^[a-zA-ZÀ-ÖØ-öø-ÿ \']*$',
                validator: VueFormGenerator.validators.regexp.locale({
                  fieldIsRequired: this.__('errors.beneficiary_first_name'),
                  invalidFormat: this.__('errors.alpha')
                })
              }
            ]
          }
        ]
      };

    },
    validateForm() {

      let has_error = false;
      let self = this;

      if(this.forbiddenNumbers.includes(this.form.main_user.phone)){
        self.$store.commit('softError', self.__('errors.phone_invalid'));
        has_error = true;
      }

      let years = new Date(Date.now()).getFullYear() - new Date(this.form.main_user.birthday).getFullYear();
      if (years < 16) {
        self.$store.commit('softError', self.__('errors.younger'));
        has_error = true;
      } else if (years > 110) {
        self.$store.commit('softError', 'Vous n’avez plus l’âge pour adhérer (moins de 110 ans).');
        has_error = true;
      }

      this.form.additional_users.forEach(function (additional_user) {

        years = new Date(Date.now()).getFullYear() - new Date(additional_user.birthday).getFullYear();

        if (years > 29 && additional_user['type_' + additional_user.index] === 'child') {

          self.$store.commit('softError', self.__('errors.beneficiary_older'));
          has_error = true;
        }
      });

      years = new Date(Date.now()).getFullYear() - new Date(this.form.main_user.birthday).getFullYear();

      if (years < 16) {

        self.$store.commit('softError', self.__('errors.younger'));
        has_error = true;
      }

      return !has_error;
    },

    showPopinIdf() {
      if (this.listcode.includes(this.form.main_user.postal_code) && this.form.main_user.status != 'idf') {
        this.showErrorCode = true
        this.titlePopin = this.__("step_1.is_idf_title1") + this.form.main_user.postal_code + this.__("step_1.is_idf_title2");
        this.messagecontent = this.__("step_1.is_idf_content");
        this.btnpopinname = this.__('transverse.continue');

        // scroll to top
        let self = this;
        setTimeout(function () {
          var postal_code = document.querySelector("#postal-code");
          var popin_content = document.querySelector(".c-popin-content");
          self.scrollToElement("#postal-code");
        }, 100)
      }
    },
    closepopin() {
      this.showErrorCode = false
    },
    submit(cont = false) {
      // popin error zipcode not Ile de france
      if (this.form.main_user.status === 'idf' && !this.listcode.includes(this.form.main_user.postal_code)) {
        this.showErrorCode = true;
        this.titlePopin = this.__('errors.no_idf');
        this.messagecontent = this.__('errors.no_idf_action');
        this.btnpopinname = this.__('transverse.close');

        let self = this;
        setTimeout(function () {
          var postal_code = document.querySelector("#postal-code");
          var popin_content = document.querySelector(".c-popin-content");
          self.scrollToElement("#postal-code");
        }, 100)

      } else {
        //console.log(this.$refs.form)
        if (!this.showErrorCode) {
          if (this.loading)
            return;

          if(!this.validateForm()){
            this.scrollToElement('#form_begin');
            return;
          }

          let self = this;

          this.loading = true;

          this.sellerCode();//get reseller code

          this.$store.dispatch('createUser', this.form).then(function () {

            dataLayer.push({
              'event': 'funnel_step1',
              'customerType': self.form.main_user.status,
            });

            let selected_date = new Date(self.form.start_date);
            self.$store.commit('selected_year', selected_date.getFullYear());
            self.$store.commit('status', (self.form.main_user.status === 'agent_territorial') ? 'terrea' : (self.form.main_user.status === 'idf') ? 'idf' : 'lmf');

            self.loading = false;
            self.$store.commit('step', 2);

          }).catch(function (e) {

            self.loading = false;

            self.$store.commit('error', e);
          });
        }


      }

    },
    checkZip(value) {

      let self = this;

      if (!value.length)
        return [self.__('errors.postal_code_empty')];

      if (!value.match(/^[0-9]{5,6}$/))
        return [self.__('errors.postal_code_invalid')];

      if (this.google.maps.Geocoder.length) {
        return new Promise((resolve, reject) => {

          let geocoder = new this.google.maps.Geocoder();

          geocoder.geocode({'address': value}, function (results, status) {
            if (status === self.google.maps.GeocoderStatus.OK && results.length > 0)
              resolve()
            else
              resolve([self.__('errors.postal_code_invalid')]);
          });

        });
      } else {
        return []
      }
    },

    sellerCode(){
      let uri = window.location.href.split('?');
      if(uri.length == 2) {
        let vars = uri[1].split('&');
        let getVars = {};
        let tmp = '';
        vars.forEach(function(v) {
          tmp = v.split('=');
          if(tmp.length == 2)
            getVars[tmp[0]] = tmp[1];
        });
        if(getVars['cmi_ref']){
          this.form.main_user.cmi_ref = getVars['cmi_ref']
        }
      }
    }
  },

  mounted() {
    this.listcode = Object.values(this.dico.idf_zipcode['']);
    let __ = this.__;
    let self = this;
    let validators = VueFormGenerator.validators;

    this.schema.main_user = {
      groups: [
        {
          fields: [
            {
              type: 'radios',
              label: __('step_1.you_are'),
              model: 'civility',
              inputName: 'civility',
              required: true,
              values: [
                {name: __('step_1.woman'), value: 'woman'},
                {name: __('step_1.men'), value: 'man'}
              ],
              validator: validators.regexp.locale({
                fieldIsRequired: __('errors.radios')
              }),
              styleClasses: ['form-group--civility']
            },
            {
              type: 'input',
              inputType: 'text',
              inputName: 'lastname',
              required: true,
              label: __('step_1.lastname'),
              model: 'lastname',
              pattern: '^[a-zA-ZÀ-ÖØ-öø-ÿ \']*$',
              validator: validators.regexp.locale({
                fieldIsRequired: __('errors.name'),
                invalidFormat: __('errors.alpha')
              }),
              styleClasses: ['form-group--inline', 'form-group--large', 'form-group--lastname']
            },
            {
              type: 'input',
              inputType: 'date',
              inputName: 'birthday',
              required: true,
              label: __('step_1.birthday'),
              placeholder: '_ _ / _ _ / _ _ _ _',
              min: '1900-01-01',
              max: '2100-01-01',
              model: 'birthday',
              validator: validators.regexp.locale({
                fieldIsRequired: __('errors.birthday')
              }),
              styleClasses: ['form-group--inline', 'form-group--small', 'input-date', 'form-group--birthday']
            },
            {
              type: 'input',
              inputType: 'text',
              inputName: 'firstname',
              required: true,
              label: __('step_1.firstname'),
              model: 'firstname',
              pattern: '^[a-zA-ZÀ-ÖØ-öø-ÿ \']*$',
              validator: validators.regexp.locale({
                fieldIsRequired: __('errors.first_name'),
                invalidFormat: __('errors.alpha')
              }),
              styleClasses: ['form-group--inline', 'form-group--large', 'form-group--firstname']
            },
            {
              type: 'input',
              inputType: 'text',
              inputName: 'postal_code',
              required: true,
              label: __('step_1.postal_code'),
              model: 'postal_code',
              maxlength: 6,
              validator: [this.checkZip],
              styleClasses: ['form-group--inline', 'form-group--small'],
              onChanged: function (model, newVal, oldVal, field) {
                if (newVal.length == 5) {
                  self.showPopinIdf();
                }
              }
            },
            {
              type: 'input',
              inputType: 'email',
              inputName: 'email',
              required: true,
              label: __('step_1.email'),
              placeholder: 'monadresseemail@maboitemail.fr',
              model: 'email',
              validator: validators.email.locale({
                fieldIsRequired: __('errors.mail_empty'),
                invalidEmail: __('errors.mail_invalid')
              }),
              styleClasses: 'form-group--inline'
            },
            {
              type: 'input',
              inputType: 'tel',
              inputName: 'phone',
              required: true,
              label: __('step_1.your_phone'),
              model: 'phone',
              min: 10,
              maxlength: 10,
              //pattern: '[0-9]{10}(?<!' + this.forbiddenNumbers.join('|') + ')',
              pattern: '[0-9]{10}',
              validator: validators.regexp.locale({
                fieldIsRequired: __('errors.phone_empty'),
                textTooSmall: __('errors.phone_invalid'),
                invalidFormat: __('errors.phone_invalid'),
                textTooBig: __('errors.phone_invalid')
              }),
              styleClasses: ['form-group--inline', 'form-group--large']
            }
          ]
        }
      ]
    };

    this.schema.additional_user = {
      groups: [
        {
          fields: [
            {
              type: 'radios',
              label: __('step_1.i_also_want_to_protect'),
              model: 'type',
              inputName: 'type',
              required: true,
              values: [
                {name: __('step_1.my_spouse'), value: 'spouse'},
                {name: __('step_1.my_child'), value: 'child'}
              ],
              styleClasses: ['type-radio', 'type-radio--spouse'],
              validator: validators.regexp.locale({
                fieldIsRequired: __('errors.radios')
              })
            },
            {
              type: 'radios',
              model: 'civility',
              inputName: 'civility',
              required: true,
              values: [
                {name: __('step_1.woman'), value: 'woman'},
                {name: __('step_1.men'), value: 'man'}
              ],
              styleClasses: ['form-group--inline', 'form-group--large'],
              validator: validators.regexp.locale({
                fieldIsRequired: __('errors.radios')
              })
            },
            {
              type: 'input',
              inputType: 'date',
              inputName: 'birthday',
              required: true,
              label: __('step_1.other_birthday'),
              placeholder: '_ _ / _ _ / _ _ _ _',
              min: '1900-01-01',
              max: '2100-01-01',
              model: 'birthday',
              styleClasses: ['form-group--inline', 'form-group--small', 'input-date']
            },
            {
              type: 'input',
              inputType: 'text',
              required: true,
              label: __('step_1.other_lastname'),
              model: 'lastname',
              styleClasses: ['form-group--inline', 'form-group--large'],
              pattern: '^[a-zA-ZÀ-ÖØ-öø-ÿ \']*$',
              validator: validators.regexp.locale({
                fieldIsRequired: __('errors.beneficiary_name'),
                invalidFormat: __('errors.alpha')
              })
            },
            {
              type: 'input',
              inputType: 'text',
              required: true,
              label: __('step_1.other_firstname'),
              model: 'firstname',
              styleClasses: ['form-group--inline', 'form-group--large'],
              pattern: '^[a-zA-ZÀ-ÖØ-öø-ÿ \']*$',
              validator: validators.regexp.locale({
                fieldIsRequired: __('errors.beneficiary_first_name'),
                invalidFormat: __('errors.alpha')
              })
            }
          ]
        }
      ]
    };

    this.schema.general = {
      groups: [
        {
          fields: [
            {
              type: 'customcheckbox',
              label: __('step_1.i_would_like_to_receive'),
              inputName: 'optin',
              model: 'optin'
            },
            {
              type: 'customcheckbox',
              label: __('step_1.i_agree'),
              model: 'rgpd',
              inputName: 'rgpd',
              required: true,
              validator: validators.required.locale({
                fieldIsRequired: __('errors.rgpd')
              }),
              get(model) {
                if (model) {
                  return model.rgpd === true ? true : '';
                }
              }
            }
          ]
        }
      ]
    };

    let profil = this.$store.getters.profil();

    if (profil)
      this.form = profil;

    this.index = this.form.additional_users.length;

    GoogleMapsApiLoader({
      apiKey: process.env.VUE_APP_GMAP,
      libraries: ['places', 'geocoder']
    }).then((google) => {
      this.google = google;
    });
  }
}
</script>

<style lang="scss">
@import '../environment';

.card{
  $c: &;

  &__part{
    #{$c}__container{
      &:before{
        mask: url("/img/status/part.svg") no-repeat center;
      }
    }
  }

  &__idf{
    #{$c}__container {
      &:before {
        mask: url("/img/status/idf.svg") no-repeat center;
      }
    }
  }

  &__agent_territorial{
    #{$c}__container{
      &:before{
        mask: url("/img/status/agent_territorial.svg") no-repeat center;
      }
    }
  }

  &__agent_etat{
    #{$c}__container{
      &:before{
        mask: url("/img/status/agent_etat.svg") no-repeat center;
      }
    }
  }

  &:hover, &.active{
    background-color: $c-secondary !important;
    border: 1px solid $c-primary !important;
    #{$c}__container{
      span{
        color: white !important;
      }
      &:before {
        background-color: $c-primary !important;
      }
      &:hover{
        box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
        cursor: pointer;
      }
    }
  }
}

.v-step {
  text-align: left;
  @media #{$to-tablet}{
    background: white;
    padding-left: 1.5rem;
    padding-right: 1.5rem
  }

  .status_list {
    display: flex;
    margin: 0 auto;
    padding: 0;
    align-items: center;
    width: 85%;
    @media #{$to-tablet}{
      width: 100%;
    }
    justify-content: space-between;

    .card {
      background: #FFFFFF;
      border: 1px solid $c-primary;
     // box-shadow: 0px 0px 10px rgba(78, 152, 112, 0.3);
      border-radius: 10px;
      transition: 0.3s;
      width: 130px;
      height: 110px;
      @media #{$to-tablet}{
        width: 23%;
      }

      &__container {
        display: flex;
        align-items: center;
        flex-direction: column;
        padding: 10px;

        &:before{
          display: block;
          width: 48px;
          height: 48px;
          background-color: $c-secondary;
          content: "";
        }

        span {
          margin-top: 10px;
          text-align: center;
          color: #3E0B7A;
        }

      }

      &__text-container {
        height: 35px;
        display: flex;
        justify-content: center;
        align-items: center;
        @media #{$to-tablet}{
          font-size: 1.2rem;
          line-height: 1.2rem;
        }
      }
    }
  }

  .errorcode {
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 26px;
    text-align: center;
    color: #3E0B7A;
  }

  .code_message {
    margin: 26px;
    font-size: 18px;
    text-align: center;
  }

  /* Add some padding inside the card container */
  .container {
    padding: 2px 16px;
  }

  h2 {
    text-align: center;
    color: $c-primary;
    font-size: 2.2rem;
    text-transform: uppercase;
    font-family: 'AvenirNextCondensed';
    font-weight: 700;

    @media #{$from-small}{
      font-size: 3.8rem;
    }

    & + small {
      margin-top: 1rem
    }
  }

  h3 {
    text-align: center;
    color: #393938;
    font-size: 2rem;
    font-weight: 700;
    margin-top: 3rem;
    @media #{$from-small}{
      font-size: 2.3rem;
    }

    a {
      text-decoration: underline
    }
  }

  .or {
    font-size: 2rem;
    text-align: center;
    color: $c-primary;
    position: relative;
    text-transform: uppercase;
    text-shadow: 0 0 4px #fff;
    margin: 4rem 0;

    &:after {
      position: absolute;
      left: 10%;
      top: 50%;
      height: 1px;
      background: $c-primary;
      width: 80%;
      content: '';
      margin-top: -1px
    }

    b {
      position: relative;
      z-index: 1;
      background: #fff;
      padding: 0 20px
    }
  }

  .mentions {
    text-align: center;
    font-weight: 300;
    color: $c-subtle;
    display: block;
  }

  .vue-form-generator {
    &.main_user{margin-top: 50px; }
    & > div {
      display: flex;
      flex-wrap: wrap;
      align-items: flex-start
    }
  }

  .form {
    margin-top: 5rem;
    @media #{$to-tablet}{
      margin-top: 3rem
    }

    .picker-block {
      background: white;
      padding: 2rem;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;

      h3 {
        font-size: 18px;
        font-weight: 300;
        margin-top: 0;
        @media #{$to-tablet}{
          margin-bottom: 1rem
        }
      }

      @media #{$to-tablet}{
        flex-direction: column
      }
    }

    .content-block {
      background: $c-background;
      margin-top: 6rem;
      padding: 2rem;
      @media #{$to-tablet}{
        margin-top: 3rem
      }
    }

    &.has-spouse .type-radio--spouse .radio-list label:first-child {
      pointer-events: none;
      opacity: 0.5
    }

    .additional-users-8 .type-radio--spouse .radio-list label {
      pointer-events: none;
      opacity: 0.5
    }

    .form-group {
      display: inline-flex;
      align-items: center;
      width: 100%;

      &.form-group--hidden{ display: none }

      &.error {
        flex-wrap: wrap;
      }

      @media #{$to-tablet}{
        flex-direction: column;
        align-items: flex-start;
        order: 1;
        &--civility, &--lastname, &--firstname {
          order: 0
        }
      }

      &.field-customcheckbox {
        justify-content: flex-end;
        align-items: flex-start;

        .field-wrap {
          margin-top: 0.1rem;
          margin-left: 0
        }

        @media #{$to-tablet}{
          flex-direction: row-reverse
        }
      }

      &.field-radios {
        .field-wrap {
          margin-top: 4px;
          @media #{$to-tablet}{
            margin-top: 0.5rem
          }
        }

        &.disabled .field-wrap {
          opacity: 0.5
        }
      }

      & + .form-group {
        margin-top: 3rem
      }

      & > label {
        font-weight: bold;
        font-size: 1.4rem;

        & + .field-wrap {
          margin-top: 0.5rem;
          @media #{$from-small}{
            margin-top: 0;
            margin-left: 2rem
          }
        }
      }

      &--inline {
        flex-direction: column;
        align-items: flex-start;
        width: 100%;

        .field-wrap {
          width: 100%
        }

        & > label {
          & + .field-wrap {
            margin-left: 0;
            margin-top: 0.5rem
          }
        }
      }

      &--small {
        width: 66%
      }

      @media #{$from-small}{
        &--large {
          width: 65%;

          & + .form-group--small {
            margin-left: 5%
          }
        }
        &--small {
          width: 30%
        }
        &--onethird {
          width: 38%;

          & + .form-group {
            margin-left: 5%
          }
        }
        &--twothird {
          width: 56%;
          align-self: flex-end;
        }
      }

      .radio-list, &.field-checkbox {
        display: inline-flex;
        align-items: center;
        font-weight: 300;

        label {
          display: flex;
          align-items: center;

          & + label {
            margin-left: 3rem
          }
        }
      }

      &.field-checkbox {
        flex-direction: row-reverse;

        .field-wrap {
          margin: 0
        }

        label {
          font-size: 1.4rem
        }
      }

    }

    .general {
      margin-top: 3.5rem;

      label {
        font-weight: 300
      }
    }

    .main_user {
      margin-bottom: 5rem;
      @media #{$to-tablet}{
        margin-bottom: 3rem
      }
    }

    .additional-users {
      margin-top: 1rem;
      border: solid 2rem $c-background;
      padding-bottom: 3rem;

      .type-radio {
        flex-direction: column;
        align-items: center;
        display: flex;
        background: $c-background;
        padding: 1rem 0 3rem;

        & ~ .form-group {
          margin-left: 3rem;
          margin-right: 3rem
        }

        .field-wrap {
          margin-top: 2rem;

          .radio-list {
            label {
              position: relative;
              z-index: 1;
              padding: 0.6rem 2rem;
              font-size: 1.6rem;
              font-weight: bold;
              cursor: pointer;
              color: $c-primary;
              transition: allow(color background);
              background: white;
              border-radius: $border-radius;
              border: solid 1px $c-primary;

              &:before {
                display: none
              }

              &:hover, &.is-checked {
                color: white;
                background: $c-primary
              }
            }

            input[type=radio] {
              margin: 0;
              position: overlay();
              width: 100%;
              height: 100%;
              z-index: -1;
              opacity: 0
            }
          }

        }
      }

      .delete-container {
        text-align: right;
        margin: 5rem 3rem 0 3rem
      }
    }
  }

  @media #{$from-small}{
    .form-container, .add-container {
      margin-left: 5rem;
      margin-right: 5rem
    }
  }

  .add-container {
    text-align: center;
    margin-top: 3rem;
    @media #{$from-small}{
      margin-top: 5rem
    }
  }

  .submit-container {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    background: $c-background;
    margin-top: 5rem;
    padding-top: 2rem;
    @media #{$to-tablet}{
      flex-direction: column-reverse;
      margin-left: -1.5rem;
      margin-right: -1.5rem;
      justify-content: center;
      padding-top: 2rem;
      .link {
        margin: 2rem auto 0
      }
    }
  }

  .input-date .wrapper {
    @include icon('calendar', 'after');
    position: relative;

    &:after {
      display: flex;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      width: 4rem;
      background: $c-primary;
      color: white;
      font-size: 2.2rem;
      align-items: center;
      justify-content: center;
      pointer-events: none;
      border-top-right-radius: $border-radius;
      border-bottom-right-radius: $border-radius
    }

    input {
      padding-right: 1rem
    }
  }

  .field-select .field-wrap {
    @include icon('down', 'after');
    position: relative;

    &:after {
      display: flex;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      width: 4rem;
      background: $c-primary;
      color: white;
      font-size: 2.2rem;
      align-items: center;
      justify-content: center;
      pointer-events: none;
      border-top-right-radius: $border-radius;
      border-bottom-right-radius: $border-radius
    }

    input {
      padding-right: 1rem
    }
  }

  label span:after {
    content: ':';
    margin-left: 0.4rem
  }

  .field-customcheckbox label span:after {
    display: none
  }

  .errors {
    color: $c-error;
    font-weight: bold;
    margin-top: 1rem;
    width: 100%
  }

  .specific-container {
    background: $c-background;
    padding-top: 3.5rem;
    text-align: justify;
    @media #{$to-tablet}{
      margin-left: -1.5rem;
      margin-right: -1.5rem;
      padding-left: 1.5rem;
      padding-right: 1.5rem
    }
  }

  .specific-regime {
    margin-top: 3rem;
  }

  .specific-regime, .rgpd {
    font-size: 1.2rem;
    font-weight: 300;
    display: block;

    & + * {
      margin-top: 2rem
    }
  }

  .rgpd {
    color: $c-subtle
  }
}
</style>
